@font-face {
  font-family: Clan;
  src: url(./ClanOT-Medium.woff) format('woff'), url(./clan-medium-webfont.ttf) format('ttf'),
    url(./ClanOT-Medium.otf) format('otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Clan;
  src: url(./clan-book-webfont.woff) format('woff'), url(./ClanOT-Book.otf) format('otf');
  font-weight: 400;
  font-style: normal;
}
