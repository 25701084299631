@import url('sanitize.css');
@import url('./fonts/fonts.css');
@import '../../node_modules/bootstrap/scss/bootstrap.scss';

$greeny-blue: #35b9b6;
$dark-indigo: #0f103e;
$dark: #0d0d1e;
$blush: #ecac94;
$orangish: #f27047;
$white: #ffffff;
$black-three: #1d1d1d;
$cerulean: #0096c9;
$salmon-two: #ff7265;
$greeny-blue: #39bbb9;
$salmon: #ff7366;
$aqua-marine: #43c9c7;

body * {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

@mixin block {
  margin: 0 auto;
  width: 100%;
  display: block;
}

@mixin flex_box {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

@mixin clear_fix {
  content: '';
  display: block;
  clear: both;
}

@mixin prefixonprop($prop, $val...) {
  -webkit-#{$prop}: $val;
  -moz-#{$prop}: $val;
  -o-#{$prop}: $val;
  -ms-#{$prop}: $val;
  #{$prop}: $val;
}

@mixin prefixonvalue($prop, $val...) {
  #{$prop}: -webkit-#{$val};
  #{$prop}: -moz-#{$val};
  #{$prop}: -o-#{$val};
  #{$prop}: -ms-#{$val};
  #{$prop}: $val;
}

.container {
  text-align: left;
  max-width: 1140px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 0;
}

.btn-rounded {
  height: 48px;
  border: 0;
  @include prefixonprop(border-radius, 24px);
  line-height: 1;
  background-color: rgba($aqua-marine, 0.1);
  display: inline-block;
  font-family: 'Clan', sans-serif;
  color: $greeny-blue;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 16px 32px;
  outline: 0;
  transition: background 0.3s ease-in;
}

.btn-arrow {
  display: inline-block;
  font-family: 'Clan', sans-serif;
  color: $aqua-marine;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  padding: 16px 24px;
  outline: 0;
  border: 0;
  position: relative;
  background: url(../images/arrow-green.svg) no-repeat right center;
  &:before {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: rgba($aqua-marine, 0.1);
    transition: width 0.4s cubic-bezier(0.475, 0.425, 0, 0.995);
  }
  &:hover {
    color: $aqua-marine;
    &:before {
      width: calc(100% + 22px);
      transition: width 0.4s cubic-bezier(0.475, 0.425, 0, 0.995);
    }
  }
  &:focus {
    color: $aqua-marine;
  }
}

ul,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

/****** headings ******/
h1 {
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.5px;
}
h2 {
  font-family: 'Clan', sans-serif;
  font-size: 52px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: -0.5px;
  color: $black-three;
  margin-bottom: 16px;
}
h3 {
  font-family: 'Clan', sans-serif;
  font-size: 32px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $black-three;
}
h5 {
  font-family: 'Clan', sans-serif;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
h6 {
  font-family: 'Open Sans', sans-serif;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $black-three;
}
p {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  color: $black-three;
  margin: 0;
}

a,
a:hover,
a:focus {
  text-decoration: none;
}
/****** END  ******/

/****** HEADER ******/
.wrapper-header {
  display: block;
  position: absolute;
  left: 0;
  top: 22px;
  width: 100%;
  .header {
    display: block;
    width: 100%;
    .container {
      padding-top: 10px;
      padding-bottom: 10px;
      &:after {
        @include clear_fix;
      }
      .logo {
        display: block;
        float: left;
        margin-top: 10px;
        img {
          max-width: 177px;
          width: 177px;
          height: auto;
        }
      }
      nav {
        float: right;
        .navbar {
          padding: 0;
          &:after {
            @include clear_fix;
          }
          li {
            display: block;
            float: left;
            a {
              display: block;
              font-family: 'Clan', sans-serif;
              font-size: 15px;
              font-weight: 500;
              font-stretch: normal;
              font-style: normal;
              line-height: 1;
              vertical-align: middle;
              letter-spacing: normal;
              color: rgba($black-three, 0.72);
              border-bottom: 0;
              outline: 0;
              padding: 6px 0 0;
              transition: color 0.3s cubic-bezier(0.475, 0.425, 0, 0.995);
              &:hover,
              &:focus {
                text-decoration: none;
              }
              &:hover {
                color: $aqua-marine;
                transition: color 0.3s cubic-bezier(0.475, 0.425, 0, 0.995);
                &:after {
                  width: 24px;
                  transition: width 0.3s cubic-bezier(0.475, 0.425, 0, 0.995);
                }
              }
              &:after {
                content: '';
                display: block;
                width: 0;
                height: 2px;
                background-color: $aqua-marine;
                transition: width 0.3s cubic-bezier(0.475, 0.425, 0, 0.995);
                margin-top: 6px;
              }
              &.btn-contact {
                height: 48px;
                padding: 16px 24px;
                line-height: 1;
                color: $aqua-marine;
                transition: background 0.3s ease-in;
                &:hover {
                  background-color: rgba($aqua-marine, 0.2);
                  transition: background 0.3s ease-in;
                  border-bottom: 0;
                }
                &:after {
                  content: none;
                }
              }
              &.active {
                color: $aqua-marine;
                &:after {
                  width: 24px;
                  background-color: $aqua-marine;
                }
              }
            }
          }
          li + li {
            margin-left: 32px;
          }
        }
      }
      #toggle_m_nav {
        width: 20px;
        height: 28px;
        position: relative;
        top: 2px;
        cursor: pointer;
        display: none;
        float: right;
        margin-top: 0px;
        &.fixed {
          position: fixed;
          z-index: 44;
        }
        #m_nav_menu {
          width: 18px;
          height: 100%;
          position: absolute;
          background: rgba(0, 0, 0, 0);
          z-index: 3;
          .m_nav_ham {
            width: 18px;
            height: 2px;
            background: $black-three;
            margin: 4px 0 0 0;
            float: right;
            @include prefixonprop(border-radius, 2px);
            @include prefixonvalue(transition, transform 0.3s ease);
          }
          #m_ham_1.m_nav_ham_1_open {
            @include prefixonprop(transform, rotate(45deg));
            @include prefixonvalue(transition, transform 0.3s ease);
            transition-duration: 0.3s;
            background: #050505;
            margin-top: 11px;
          }
          #m_ham_2.m_nav_ham_2_open {
            @include prefixonprop(transform, rotate(-45deg));
            margin-top: -2px;
            @include prefixonvalue(transition, transform 0.3s ease);
            background: #050505;
            transition-duration: 0.3s;
          }
          #m_ham_3.m_nav_ham_3_open {
            display: none;
          }
        }
      }
    }
  }
}
/****** END HEADER ******/
/****** animation header class ******/
.cd-auto-hide-header {
  position: fixed;
  top: 10px;
  left: 0;
  width: 100%;
  height: 70px;
  z-index: 999;
  background-color: transparent;
  transition: box-shadow 0.7s, transform 0.5s, background-color 0.5s;
  &.shadow-header {
    background-color: $white;
    top: 0;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.7s, transform 0.5s, background-color 0.7s;
  }
}
.cd-auto-hide-header.is-hidden {
  transform: translateY(-100%);
}
/****** END animation header class ******/
/****** FOOTER ******/
.footer {
  @include block;
  .container {
    border-top: 1px solid rgba(13, 13, 30, 0.08);
    padding-top: 64px;
    .nav-footer {
      display: block;
      margin-bottom: 22px;
      h1 {
        display: none;
      }
      &:after {
        @include clear_fix;
      }
      .col-md-6 {
        float: left;
        .logo {
          display: block;
          img {
            width: 166px;
            height: auto;
          }
        }
        p {
          padding-top: 32px;
          max-width: 445px;
        }
        .btn-contact {
          font-family: 'Clan', sans-serif;
          font-size: 12px;
          font-weight: 500;
          line-height: 1;
          vertical-align: middle;
          height: auto;
          padding: 13px 24px;
          @include prefixonprop(border-radius, 24px);
          border: solid 1px rgba($blush, 0.24);
          background-color: rgba($blush, 0.16);
          color: #e59071;
          margin-top: 16px;
        }
        p + p {
          margin-top: 16px;
          display: block;
          font-weight: normal;
          a {
            color: $aqua-marine;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      nav {
        @include prefixonvalue(display, flex);
        justify-content: flex-end;
        > .col {
          padding: 0;
          h2 {
            font-family: 'Clan', sans-serif;
            font-size: 15px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            color: $black-three;
            margin-bottom: 0;
          }
          ul {
            display: block;
            padding-top: 16px;
            li {
              display: block;
              margin-top: 16px;
              a {
                display: block;
                font-family: 'Open Sans', sans-serif;
                font-size: 16px;
                font-weight: normal;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $black-three;
                transition: color 0.3s ease-in;
                &:hover {
                  color: $aqua-marine;
                  transition: color 0.3s ease-in;
                }
              }
            }
          }
          &:last-child {
            max-width: 112px;
          }
        }
      }
    }
    .copy-footer {
      display: block;
      padding: 48px 0;
      &:after {
        @include clear_fix;
      }
      p {
        opacity: 0.48;
        font-size: 13px;
        line-height: normal;
        color: $black-three;
        display: inline-block;
        &:first-child {
          float: left;
        }
        &:last-child {
          float: right;
        }
      }
    }
  }
}
/****** END FOOTER ******/
/****** CONTACT ******/
.contact {
  @include block;
  .container {
    text-align: center;
    padding-top: 60px;
    padding-bottom: 88px;
    h1 {
      color: $aqua-marine;
      margin-bottom: 24px;
      text-transform: uppercase;
    }
    form {
      max-width: 540px;
      margin: 64px auto 0;
      display: block;
      .field {
        display: block;
        margin-top: 24px;
        label {
          font-family: 'Open Sans', sans-serif;
          font-size: 13px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          color: rgba($black-three, 0.56);
          text-transform: uppercase;
          margin-bottom: 12px;
          display: block;
          text-align: left;
        }
        input,
        textarea,
        select {
          @include prefixonprop(appearance, none);
          resize: none;
          width: 100%;
          height: 48px;
          color: $black-three;
          @include prefixonprop(border-radius, 4px);
          border: solid 1px rgba($dark, 0.03);
          background-color: rgba($dark, 0.03);
          padding: 10px 24px;
          &:focus {
            border: solid 1px rgba($dark, 0.1);
            outline: 0;
          }
        }
        select {
          background: url(../images/arrow-drop.svg) no-repeat calc(100% - 24px) center
            rgba($dark, 0.03);
        }
        textarea {
          height: 96px;
        }
        .btn-submit {
          @include prefixonprop(appearance, none);
          text-align: left;
          margin-left: 0;
          display: block;
        }
      }
    }
  }
}
/****** END CONTACT ******/

/*** responsive ***/
@media only screen and (max-width: 1170px) {
  /****** HEADER ******/
  .wrapper-header {
    top: 14px;
    .header {
      .container {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
  /****** FOOTER ******/
  .footer {
    .container {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
@media only screen and (max-width: 960px) {
  /****** FOOTER ******/
  .footer {
    .container {
      .nav-footer {
        .col-md-6 {
          .col-3 {
            width: 33.33%;
            max-width: 33.33%;
            flex: 0 0 33.33%;
            &:last-child {
              max-width: 33.33%;
            }
          }
          &:last-child {
            margin-top: 64px;
          }
        }
      }
      .copy-footer {
        p {
          display: block;
          float: none !important;
          &:last-child {
            padding-top: 32px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 768px) {
  /****** HEADER ******/
  .wrapper-header {
    top: 14px;
    .header {
      .container {
        .logo {
          margin-top: 2px;
          img {
            max-width: 129px;
          }
        }
        nav {
          display: none;
          position: fixed;
          width: 100%;
          height: calc(100% - 47px);
          z-index: 999;
          left: 0;
          top: 47px;
          background-color: $white;
          float: none;
          .navbar {
            flex-direction: column;
            height: 100%;
            justify-content: center;
            li {
              float: none;
              display: block;
              margin-bottom: 36px;
              a {
                font-size: 28px;
                &.btn-contact {
                  @include prefixonprop(border-radius, 44px);
                  height: 63px;
                  padding: 17px 32px;
                }
              }
              &:last-child {
                margin-bottom: 0;
              }
            }
            li + li {
              margin-left: 0;
            }
          }
        }
        #toggle_m_nav {
          display: block;
        }
      }
    }
  }
  /****** END HEADER ******/
  .cd-auto-hide-header {
    height: 48px;
  }
  /****** CONTACT ******/
  .contact {
    .container {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
@media only screen and (max-width: 640px) {
  h2 {
    font-size: 36px;
    text-align: left;
    letter-spacing: -1px;
  }
  /****** FOOTER ******/
  .footer {
    .container {
      .nav-footer {
        nav.col-md-6 {
          flex-wrap: wrap;
          justify-content: flex-start;
          .col-3 {
            width: 50%;
            max-width: 50%;
            flex: 50%;
            &:last-child {
              width: 100%;
              max-width: 100%;
              flex: 100%;
              margin-top: 70px;
              ul {
                li {
                  display: inline-block;
                  width: 32.3%;
                }
              }
            }
          }
        }
      }
    }
  }
  /****** CONTACT ******/
  .contact {
    .container {
      text-align: left;
      form {
        max-width: 100%;
      }
    }
  }
}
