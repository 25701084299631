@import url('sanitize.css');
@import 'owl.carousel/dist/assets/owl.carousel.css';
@import 'base';

.main-content {
  @include block;
  overflow: hidden;
  .hero {
    @include block;
    height: 995px;
    overflow: hidden;
    .container {
      position: relative;
      height: 100%;
      max-width: 1140px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .container-text {
        .align-center {
          margin-top: 80px;
        }
        left: 0;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h1 {
          font-family: 'Open Sans', sans-serif;
          font-size: 13px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.5px;
          display: block;
          color: $salmon-two;
          margin-bottom: 24px;
          text-transform: uppercase;
        }
        h2 {
          max-width: 540px;
        }
        p {
          max-width: 445px;
        }
        .btn-arrow {
          display: inline-block;
          color: $aqua-marine;
          margin-top: 32px;
        }
        .social {
          display: block;
          margin-top: 144px;
          h3 {
            font-family: 'Open Sans', sans-serif;
            font-size: 13px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.5px;
            color: #aaaaaa;
            text-transform: uppercase;
          }
          ul {
            list-style: none;
            display: inline-block;
            margin-top: 24px;
            li {
              display: inline-block;
              a {
                display: block;
                width: 24px;
                height: 24px;
                outline: 0;
                transition: background 0.3s ease-in;
                &.twitter {
                  background: url(../images/home/icon-twitter.svg) no-repeat center;
                }
                &.ig {
                  background: url(../images/home/icon-instagram.svg) no-repeat center;
                }
                &.in {
                  background: url(../images/home/icon-linked-in.svg) no-repeat center;
                }
                &.blog {
                  background: url(../images/home/icon-world.svg) no-repeat center;
                }
                &:hover {
                  transition: background 0.3s ease-in;
                  &.twitter {
                    background: url(../images/home/icon-twitter-active.svg) no-repeat center;
                  }
                  &.ig {
                    background: url(../images/home/icon-instagram-active.svg) no-repeat center;
                  }
                  &.in {
                    background: url(../images/home/icon-linked-in-active.svg) no-repeat center;
                  }
                  &.blog {
                    background: url(../images/home/icon-world-active.svg) no-repeat center;
                  }
                }
              }
            }
          }
        }
      }
      .container-images {
        position: relative;
        right: 0;
        width: 50%;
        height: 100%;
        .mobile-cards {
          display: none;
        }
        .align-center {
          position: absolute;
          width: 834px;
          left: 156px;
          height: 100%;
          top: 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          align-self: flex-start;
        }
        .group-card {
          display: flex;
          flex-direction: column;
          align-items: center;
          .card + .card {
            margin-top: 30px;
          }
        }
        .primary-block + .primary-block {
          margin-left: 30px;
        }
        .card {
          outline: 0;
          border: 0;
          img {
            display: block;
            max-width: 255px;
            height: auto;
            width: 255px;
          }
        }
      }
    }
  }
  .global-scope {
    @include block;
    .container {
      position: relative;
      max-width: 1682px;
      display: flex;
      align-items: center;
      margin-top: 48px;
      margin-bottom: 100px;
      .col-12 {
        padding: 0;
        img {
          max-width: 1016px;
          width: 100%;
          display: block;
          height: auto;
        }
        .container-text {
          background-color: $black-three;
          max-width: 540px;
          padding: 64px 95px;
          left: -164px;
          position: relative;
          h2 {
            color: $white;
          }
          p {
            color: rgba($white, 0.8);
            max-width: 330px;
          }
        }
      }
    }
  }
  #module-genomics {
    @include block;
    .container {
      position: relative;
      text-align: center;
      padding: 140px 0;
      .col-12 {
        padding: 0;
        max-width: 1000px;
        margin: 0 auto;
        .row {
          .col-sm-3 {
            padding: 0;
            .container-text {
              margin-top: 116px;
              position: relative;
              h3 {
                font-size: 24px;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                text-align: center;
                color: $black-three;
              }
              p {
                text-transform: uppercase;
                font-size: 13px;
                font-weight: 600;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: 0.5px;
                text-align: center;
                color: rgba($black-three, 0.18);
                margin-top: 16px;
                transition: color 0.2s ease-in;
                &.active {
                  color: $salmon;
                  transition: color 0.25s ease-in;
                }
              }
            }
            &:last-child {
              .container-text {
                &:before {
                  display: none;
                }
              }
            }
            .animation {
              display: block;
              width: 195px;
              height: 163px;
              position: relative;
              margin: 0 auto;
              .shape {
                position: absolute;
                left: 50%;
                top: 50%;
                width: 100%;
                height: auto;
                transform: translate(-50%, -50%);
              }
              &#search-container {
                width: 155px;
                .shape {
                  transform: translate(-42%, -50%);
                }
                video {
                  width: 100%;
                  height: auto;
                }
              }
              &#sequence-container {
                width: 158px;
                video {
                  width: 100%;
                  height: auto;
                }
              }
              &#discover-container {
                width: 154px;
                video {
                  width: 100%;
                  height: auto;
                }
              }
              &#treat-container {
                width: 154px;
                .shape {
                  transform: translate(-42%, -50%);
                }
                video {
                  width: 100%;
                  height: auto;
                }
              }
            }
          }
        }
        .container-text {
          margin-top: 64px;
          p {
            max-width: 540px;
            margin: 0 auto;
            display: block;
          }
          .btn-arrow {
            margin-top: 32px;
          }
        }
      }
    }
    .container-dots {
      display: flex;
      position: absolute;
      top: 233px;
      left: 50%;
      transform: translateX(-50%);
      max-width: 820px;
      width: 83%;
      justify-content: space-between;
      margin: 0 auto;
      border-top: 2px solid rgba($black-three, 0.05);
      .dot {
        width: 44px;
        height: 44px;
        display: inline-block;
        margin: -22px 0 24px;
        position: relative;
        border-radius: 50%;
        border: 7px solid $white;
        background-color: $white;
        &:before {
          display: block;
          content: '';
          width: 10px;
          height: 10px;
          background-color: #cfcfcf;
          border-radius: 50%;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 2;
        }
        &:after {
          display: block;
          content: '';
          left: 33%;
          top: 33%;
          border-radius: 50%;
          position: absolute;
          opacity: 0;
          background-color: $white;
          width: 10px;
          height: 10px;
          z-index: 1;
          transform-origin: center;
        }
        &.active {
          &:before {
            background-color: $salmon;
          }
          &:after {
            background-color: rgba($salmon, 0.08);
            -webkit-animation: pulsate 1s ease-out;
            -webkit-animation-iteration-count: infinite;
          }
        }
        @-webkit-keyframes pulsate {
          0% {
            -webkit-transform: scale(0, 0);
            opacity: 0;
          }
          50% {
            opacity: 1;
          }
          100% {
            -webkit-transform: scale(4, 4);
            opacity: 0;
          }
        }
      }
    }
  }
  #mission {
    @include block;
    margin-top: 144px;
    margin-bottom: 144px;
    .container {
      height: 860px;
      max-width: 100%;
      background: url(../images/home/team-work-home.png) no-repeat center top;
      background-size: cover;
      position: relative;
      .container-text {
        display: block;
        width: calc(100% - 16%);
        z-index: 2;
        position: absolute;
        left: 16%;
        bottom: -90px;
        background-color: $white;
        padding: 112px 0 0 190px;
        p {
          max-width: 635px;
          padding-bottom: 32px;
        }
      }
    }
  }
  #partner {
    @include block;
    overflow: hidden;
    margin-bottom: 144px;
    .row {
      margin-top: 144px;
      display: flex;
      flex-direction: row;
      align-items: center;
      .col-12 {
        img {
          max-width: 826px;
          width: 100%;
          height: auto;
        }
        .container-text {
          padding-left: 83px;
          p {
            max-width: 460px;
            padding-bottom: 32px;
          }
        }
      }
    }
  }
  #where-are-we {
    @include block;
    .container {
      text-align: center;
      padding-top: 144px;
      padding-bottom: 120px;
      img {
        max-width: 920px;
        width: 100%;
        display: block;
      }
      .mobile {
        display: none;
      }
      .container-images {
        max-width: 920px;
        width: 100%;
        display: block;
        margin: 56px auto 0;
        position: relative;
        display: block;
        .pulse {
          display: block;
          position: absolute;
          > span {
            width: 30px;
            height: 30px;
            display: block;
            margin: 0 auto 24px;
            position: relative;
            border-radius: 50%;
            &:before {
              display: block;
              content: '';
              width: 10px;
              height: 10px;
              background-color: $aqua-marine;
              border-radius: 50%;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              z-index: 2;
            }
            &:after {
              display: block;
              content: '';
              left: 33%;
              top: 33%;
              border-radius: 50%;
              position: absolute;
              opacity: 0;
              background-color: $white;
              width: 10px;
              height: 10px;
              z-index: 1;
              transform-origin: center;
              background-color: rgba($aqua-marine, 0.08);
              -webkit-animation: pulsateGreen 1s ease-out;
              -webkit-animation-iteration-count: infinite;
            }
          }
          &#pulse-1 {
            left: 17px;
            bottom: 87px;
          }
          &#pulse-2 {
            left: 164px;
            top: 210px;
          }
          &#pulse-3 {
            left: 388px;
            top: 77px;
          }
          &#pulse-4 {
            left: 410px;
            top: 120px;
          }
          &#pulse-5 {
            left: 450px;
            top: 110px;
          }
          &#pulse-6 {
            left: 407px;
            top: 220px;
          }
          &#pulse-7 {
            right: 310px;
            top: 187px;
          }
          &#pulse-8 {
            right: 254px;
            top: 194px;
          }
          &#pulse-9 {
            right: 274px;
            top: 227px;
          }
          &#pulse-10 {
            right: 32px;
            bottom: 17px;
          }
          @-webkit-keyframes pulsateGreen {
            0% {
              -webkit-transform: scale(0, 0);
              opacity: 0;
            }
            50% {
              opacity: 1;
            }
            100% {
              -webkit-transform: scale(4, 4);
              opacity: 0;
            }
          }
        }
      }
    }
  }
  #team {
    @include block;
    overflow: hidden;
    margin-bottom: 120px;
    .container {
      max-width: 1682px;
      .row {
        margin-top: 144px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .col-12 {
          flex: auto;
          img {
            max-width: 1111px;
            width: 100%;
            height: auto;
            margin: 56px 0 0 auto;
            display: block;
          }
          .mobile {
            display: none;
          }
          .container-text {
            max-width: 1140px;
            display: block;
            margin: 0 auto;
            p {
              max-width: 540px;
              padding-bottom: 32px;
            }
          }
        }
      }
    }
  }
  /*** responsive ***/
  @media only screen and (max-width: 1440px) {
    .hero {
      height: 900px;
      .container {
        .container-images {
          .align-center {
            left: 30px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1170px) {
    .hero {
      height: 900px;
      .container {
        .container-text {
          padding-left: 15px;
          padding-right: 15px;
        }
        .container-images {
          .align-center {
            left: 0;
          }
        }
      }
    }
    #team {
      .container {
        .row {
          .col-12 {
            .container-text {
              padding: 0 15px;
            }
          }
        }
      }
    }
    #module-genomics {
      .container {
        padding: 0 15px;
      }
    }
  }
  @media only screen and (max-width: 900px) {
    #mission {
      .container {
        height: 500px;
        .container-text {
          padding: 112px 0 0 85px;
        }
      }
    }
    #partner {
      .row {
        .col-12 {
          .container-text {
            padding-right: 15px;
            padding-left: 0;
          }
        }
      }
    }
    #where-are-we {
      .container {
        h2 {
          text-align: center !important;
        }
        p {
          max-width: 276px;
          margin: 0 auto;
        }
        padding: 0 15px;
        .container-images {
          .pulse,
          .desktop {
            display: none;
          }
          .mobile {
            display: block;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 768px) {
    .hero {
      height: auto;
      .container {
        margin-top: 33px;
        flex-direction: column;
        height: auto;
        .container-text {
          width: 100%;
          .social {
            margin-top: 48px;
          }
          p {
            margin-top: 250px;
          }
        }
        .container-images {
          position: absolute;
          left: 0;
          top: 242px;
          width: 100%;
          height: 210px;
          margin-top: 32px;
          .desktop-cards {
            display: none;
          }
          .mobile-cards {
            display: block;
            height: 193px;
            .card {
              vertical-align: top;
              img {
                max-width: 158px;
              }
            }
          }
        }
      }
    }
    .global-scope {
      .container {
        flex-direction: column;
        .col-12 {
          flex: auto;
          max-width: 100%;
          &:first-child {
            order: 2;
          }
          &:last-child {
            order: 1;
          }
          .container-text {
            padding: 48px 32px;
            position: static;
            margin-left: 15px;
            margin-bottom: -48px;
            p {
              max-width: 100%;
            }
          }
        }
      }
    }
    #module-genomics {
      .container {
        padding-bottom: 160px;
      }
    }
    #mission {
      margin-top: 0;
      margin-bottom: 100px;
      .container {
        height: auto;
        background-size: 100% auto;
        padding-top: 300px;
        .container-text {
          position: static;
          padding: 48px 15px;
          width: 100%;
        }
      }
    }
    #partner {
      margin-bottom: 100px;
      .row {
        margin-top: 0;
        flex-direction: column;
        .col-12 {
          flex: auto;
          max-width: 100%;
          width: 100%;
          .container-text {
            padding: 48px 15px;
            p {
              max-width: 100%;
              padding-bottom: 32px;
            }
          }
        }
      }
    }
    #team {
      .container {
        .row {
          .col-12 {
            flex: auto;
            max-width: 100%;
            width: 100%;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 640px) {
    .hero {
      .container {
        .container-text {
          h2 {
            max-width: 340px;
          }
        }
        .container-images {
          top: 210px;
        }
      }
    }
    #mission {
      .container {
        background-size: auto 300px;
        background-position: center top;
      }
    }
    #module-genomics {
      .container {
        .col-12 {
          .row {
            position: relative;
            height: 337px;
            .col-12 {
              width: 100%;
              flex: auto;
              max-width: 100%;
              position: absolute;
              left: 0;
              top: 0;
              opacity: 0;
              transition: opacity 0.3s ease-in;
              &.active {
                opacity: 1;
                transition: opacity 0.3s ease-in;
              }
              .animation {
                width: 212px !important;
              }
            }
            .container-dots {
              width: 96%;
            }
          }
          .container-text {
            h2 {
              text-align: center !important;
            }
          }
        }
      }
    }
    #team {
      margin-bottom: 100px;
      .container {
        .row {
          .col-12 {
            .desktop {
              display: none;
            }
            .mobile {
              display: block;
            }
          }
        }
      }
    }
  }
}
